@media print {
  body {
    background-color: #fff; }
  .container {
    width: auto;
    max-width: 100%;
    padding: 0; }
  .cover, .cover-bg {
    border-radius: 0; }
  .cover.shadow-lg {
    box-shadow: none !important; }
  .cover-bg {
    padding: 5rem !important;
    padding-bottom: 10px !important; }
  .avatar {
    margin-top: -10px; }
  .about-section {
    padding: 6.5rem 5rem 2rem !important; }
  .skills-section,
  .work-experience-section,
  .education-section,
  .contant-section {
    padding: 1.5rem 5rem 2rem !important; }
  .page-break {
    padding-top: 5rem;
    page-break-before: always; } }
