/* Buttons */
/* Inputs */
/* Timeline custom component */
::-webkit-scrollbar {
  display: none;
}
.timeline {
  border-left: 2px solid #e6e9ed;
  padding: 1rem 0;
}
.Font-Persian {
  text-align: center;
}
.tracks{
padding: 12px;
text-align: center;
}
.otherskills{
  display:flex;
}
.timeline-card {
  position: relative;
  margin-left: 31px;
  border-left: 2px solid;
  margin-bottom: 2rem;
}

.timeline-card:last-child {
  margin-bottom: 1rem;
}

.timeline-card:before {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  top: 20px;
  left: -41px;
  border: 2px solid;
  z-index: 2;
}

.timeline-card:after {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: currentColor;
  width: 29px;
  height: 2px;
  top: 25px;
  left: -29px;
  z-index: 1;
}

.timeline-card-primary {
  border-left-color: #4a89dc;
}

.timeline-card-primary:before {
  border-color: #4a89dc;
}

.timeline-card-primary:after {
  background-color: #4a89dc;
}

.timeline-card-success {
  border-left-color: #37bc9b;
}

.timeline-card-success:before {
  border-color: #37bc9b;
}

.timeline-card-success:after {
  background-color: #37bc9b;
}

html {
  scroll-behavior: smooth;
}

.site-title {
  font-size: 1.25rem;
  line-height: 2.5rem;
}

.nav-link {
  padding: 0;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: rgba(0, 0, 0, 0.5);
}
.fade-in-zoom {
  animation: animatezoom 1.5s;
}
@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.fade-in-top {
  position: relative;
  animation: animatetop 0.4s;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.fade-in-right {
  position: relative;
  animation: animateleft 1.5s;
}
@keyframes animateleft {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
.fade-in-right2 {
  position: relative;
  animation: animateleft 1.3s;
}
@keyframes animateleft {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
.fade-in-right3 {
  position: relative;
  animation: animateleft 1s;
}
@keyframes animateleft {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.fade-in-progressbar {
  animation: animatelefts 1s;
}
@keyframes animatelefts {
  from {
    width: 0%;
  }
  to {
    width: 95%;
  }
}
.fade-in-progressbar2 {
  animation: animatelefts2 1s;
}
@keyframes animatelefts2 {
  from {
    width: 0%;
  }
  to {
    width: 85%;
  }
}
.fade-in-progressbar3 {
  animation: animatelefts3 1s;
}
@keyframes animatelefts3 {
  from {
    width: 0%;
  }
  to {
    width: 80%;
  }
}
.fade-in-progressbar4 {
  animation: animatelefts4 1s;
}
@keyframes animatelefts4 {
  from {
    width: 0%;
  }
  to {
    width: 85%;
  }
}
.fade-in-progressbar5 {
  animation: animatelefts5 1s;
}
@keyframes animatelefts5 {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}
.fade-in-progressbar6 {
  animation: animatelefts6 1s;
}
@keyframes animatelefts6 {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
.nav-link:hover,
.nav-link:focus,
.active .nav-link {
  color: rgba(0, 0, 0, 0.8);
}

.nav-item + .nav-item {
  margin-left: 1rem;
}
.fab{
  color: green;
}
.cover {
  border-radius: 10px;
}

.cover-bg {
  background-color: green;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.12'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  border-radius: 3px 3px 0 0;
}

.avatar {
  max-width: 216px;
  max-height: 216px;
  margin-top: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.avatar img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(10%);
}

footer a:not(.nav-link) {
  color: inherit;
  border-bottom: 1px dashed;
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 48em) {
  .site-title {
    float: left;
  }
  .site-nav {
    float: right;
  }
  .avatar {
    margin-bottom: -80px;
    margin-left: 0;
  }
}

@media print {
  body {
    background-color: #fff;
  }
  .container {
    width: auto;
    max-width: 100%;
    padding: 0;
  }

  .cover,
  .cover-bg {
    border-radius: 0;
  }
  .cover.shadow-lg {
    box-shadow: none !important;
  }
  .cover-bg {
    padding: 5rem !important;
    padding-bottom: 10px !important;
  }
  .avatar {
    margin-top: -10px;
  }
  .about-section {
    padding: 6.5rem 5rem 2rem !important;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .about-section-p {
    text-align: center;
  }
  .skills-section,
  .work-experience-section,
  .education-section,
  .contant-section {
    padding: 1.5rem 5rem 2rem !important;
  }
  .page-break {
    padding-top: 5rem;
    page-break-before: always;
  }
}
.project-button {
  background-color: #37bc9b;
  color: white;
}
