html {
  scroll-behavior: smooth;
}

.site-title {
  font-size: 1.25rem;
  line-height: 2.5rem;
}

.nav-link {
  padding: 0;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: rgba(0, 0, 0, 0.5);
}

.nav-link:hover,
.nav-link:focus,
.active .nav-link {
  color: rgba(0, 0, 0, 0.8);
}

.nav-item + .nav-item {
  margin-left: 1rem;
}

.cover {
  border-radius: 10px;
}

.avatar {
  max-width: 216px;
  max-height: 216px;
  margin-top: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.avatar img {
  /* Safari 6.0 - 9.0 */
  /* filter: grayscale(100%);  */
}

footer a:not(.nav-link) {
  color: inherit;
  border-bottom: 1px dashed;
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 48em) {
  .site-title {
    float: left;
  }
  .site-nav {
    float: right;
  }
  .avatar {
    margin-bottom: -80px;
    margin-left: 0;
  }
}
.comment{
  display: none;
}
.container2:hover .comment{
  display: none;
  
}

iframe{
  width: 100%;
  height: 50vh;
  border:1px solid black;
  transform: scale(1);
  margin-top: 10px;
}
.portal-image{
  width: 100%;
  height: 100%;
  margin: 5px;
  border: 2px solid black;
}