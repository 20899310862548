.container {
  display: flex;
  justify-content: space-between;
}
.containerfooter {
  display: flex;
  justify-content: center;
}
.LinkTo {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  background-color: black;
  color: white;
  padding: 6px;
  margin: 18px;
  border: 2px solid green;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.3s;
}
.LinkTo:hover {
  transition: 0.3s;
  background-color: white;
  color: black;
}


.ghost {
  animation: float 3s ease-out infinite;
}

@keyframes float {
  50% {
     transform: translate(0, 20px);
  }
}
.shadowFrame {
  width: 130px;
  margin-top: 15px;
}
.shadowAnimation {
  animation: shrink 3s ease-out infinite;
  transform-origin: center center;
  ellipse {
    transform-origin: center center;
  }
}

@keyframes shrink {
  0% {
    width: 90%;
    margin: 0 5%;
  }
  50% {
    width: 60%;
    margin: 0 18%;
  }
  100% {
    width: 90%;
    margin: 0 5%;
  }
}

.night{
  background-position: 0 0;
}
.content{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.content > i{
font-size: 44px;
transition: all .2s ease-in;
}
/* custom checkbox*/
.switch {
  position: relative;
  display: block;
  height: 20px;
  width: 44px;
  background: #303030;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.switch:after {
  position: absolute;
  left: -2px;
  top: -3px;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background: black;
  box-shadow: 0px 3px 3px rgba(0,0,0,0.05);
  content: '';
  transition: all 0.3s ease;
}
.switch:active:after { transform: scale(1.15, 0.85); }
.cbx:checked ~ label { background: #EBEBEB; }
.cbx:checked ~ label:after {
  left: 20px;
  background: #FFFFFF;
}
.cbx:disabled ~ label {
  background: #d5d5d5;
  pointer-events: none;
}
.cbx:disabled ~ label:after { background: #bcbdbc; }
.hidden { display: none; }


.cover {
  border-radius: 12px; /* Rounded corners */
}

.cover-border {
  border: 5px solid green; /* Red border */
  box-shadow: 0 0 20px 5px red; /* Red glowing effect */
}

.cover-content {
  padding: 20px;
  color: black; /* Text color */
  font-family: Arial, sans-serif;
}
